import React from "react";
import { ColumnData } from "../../components/CustomDataGrid/types";

import OpenEditBtn from "../../components/OpenEditBtn";
import { ROUTE_PATHS } from "../../consts";
import { VirtualGoodsType } from "../../slices/virtualGoods/types";
import {
  CreateVirtualGoodFormValues,
  SetProbabilityConfigFormValues,
} from "./types";

export const PRESET_DEFAULT = "default" as const;

export const PresetCount = [1, 2, 3, 4, 5, 6];

export enum VirtualGoodType {
  VGOOD = "VGOOD",
  CURRENCY = "CURRENCY",
}

export const ProbabilityElementName = {
  air: "air",
  water: "water",
  ground: "ground",
  forest: "forest",
};

export enum ProbabilityConfigGroupName {
  character = "character",
  items = "items",
  pets = "pets",
  food = "food",
}

export const ProbabilityConfigGroupNameArray = [
  "character",
  "items",
  "pets",
  "food",
];

export const ProbabilityConfigOptionName = {
  water_1: "water_1",
  water_2: "water_2",
  water_3: "water_3",
  water_4: "water_4",
  water_5: "water_5",
  water_6: "water_6",
  air_1: "air_1",
  air_2: "air_2",
  air_3: "air_3",
  air_4: "air_4",
  air_5: "air_5",
  air_6: "air_6",
  ground_1: "ground_1",
  ground_2: "ground_2",
  ground_3: "ground_3",
  ground_4: "ground_4",
  ground_5: "ground_5",
  ground_6: "ground_6",
  forest_1: "forest_1",
  forest_2: "forest_2",
  forest_3: "forest_3",
  forest_4: "forest_4",
  forest_5: "forest_5",
  forest_6: "forest_6",
};

export const createVirtualGoodInitialValues: CreateVirtualGoodFormValues = {
  name: "",
  description: "",
  rarity: 1,
  type: VirtualGoodType.VGOOD,
  shortCode: "",
  tags: [],
  disabled: false,
};

export const setProbabilityConfigInitialValues: SetProbabilityConfigFormValues =
  {
    groupType: ProbabilityConfigGroupName.items,
    shortCode: "",
    preset: "",
    name: "",
    water_1: "" as unknown as number,
    water_2: "" as unknown as number,
    water_3: "" as unknown as number,
    water_4: "" as unknown as number,
    water_5: "" as unknown as number,
    water_6: "" as unknown as number,
    air_1: "" as unknown as number,
    air_2: "" as unknown as number,
    air_3: "" as unknown as number,
    air_4: "" as unknown as number,
    air_5: "" as unknown as number,
    air_6: "" as unknown as number,
    ground_1: "" as unknown as number,
    ground_2: "" as unknown as number,
    ground_3: "" as unknown as number,
    ground_4: "" as unknown as number,
    ground_5: "" as unknown as number,
    ground_6: "" as unknown as number,
    forest_1: "" as unknown as number,
    forest_2: "" as unknown as number,
    forest_3: "" as unknown as number,
    forest_4: "" as unknown as number,
    forest_5: "" as unknown as number,
    forest_6: "" as unknown as number,
  };

export const managePresetsInitialValues: SetProbabilityConfigFormValues = {
  groupType: ProbabilityConfigGroupName.items,
  shortCode: "",
  preset: "",
  name: "",
  water_1: "" as unknown as number,
  water_2: "" as unknown as number,
  water_3: "" as unknown as number,
  water_4: "" as unknown as number,
  water_5: "" as unknown as number,
  water_6: "" as unknown as number,
  air_1: "" as unknown as number,
  air_2: "" as unknown as number,
  air_3: "" as unknown as number,
  air_4: "" as unknown as number,
  air_5: "" as unknown as number,
  air_6: "" as unknown as number,
  ground_1: "" as unknown as number,
  ground_2: "" as unknown as number,
  ground_3: "" as unknown as number,
  ground_4: "" as unknown as number,
  ground_5: "" as unknown as number,
  ground_6: "" as unknown as number,
  forest_1: "" as unknown as number,
  forest_2: "" as unknown as number,
  forest_3: "" as unknown as number,
  forest_4: "" as unknown as number,
  forest_5: "" as unknown as number,
  forest_6: "" as unknown as number,
};

export const dataTableColumns: ColumnData<VirtualGoodsType>[] = [
  {
    width: 100,
    label: "N",
    dataKey: "id",
    renderCell: (rowData, rowIndex: number) => rowIndex + 1,
  },
  {
    width: 300,
    label: "Name",
    dataKey: "name",
    renderCell: ({ name }) => name,
  },
  {
    width: 300,
    label: "Description",
    dataKey: "description",
    renderCell: ({ description }) => description,
  },
  {
    width: 300,
    label: "shortCode",
    dataKey: "shortCode",
    renderCell: ({ shortCode }) => shortCode,
  },
  {
    width: 250,
    label: "type",
    dataKey: "type",
    renderCell: ({ type }) => type,
  },
  {
    width: 800,
    label: "tags",
    dataKey: "tags",
    renderCell: ({ tags }) =>
      tags?.length > 0
        ? tags.reduce(
            (result: string, tag: string, idx: number) =>
              idx === 0 ? tag : `${result}, ${tag}`,
            ""
          )
        : null,
  },
  {
    width: 120,
    label: "Action",
    dataKey: "action",
    renderCell: ({ _id }) => (
      <OpenEditBtn id={_id} routePath={ROUTE_PATHS.V_GOODS} />
    ),
  },
];

export const requiredFields = [
  "name",
  "description",
  "shortCode",
  "type",
  "tags",
];
