export enum QueryListName {
  character = "character",
  sceneType = "sceneType",
  virtualGoodShortCodes = "virtualGoodShortCodes",
  virtualGoodTags = "virtualGoodTags",
  virtualGoodBaseFields = "virtualGoodBaseFields",
  virtualGoodCharacterPetFields = "virtualGoodCharacterPetFields",
}

export enum StaticListName {
  virtualGoodCurrencies = "virtualGoodCurrencies",
  rarityValues = "rarityValues",
  characterTags = "characterTags",
}

export type ListNameType = `${QueryListName}` | `${StaticListName}`;

export type ListRequestBodyDto = `${QueryListName}`;

export type ListsResponseDto = string[];
