import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import ControlledInputText from "../../components/inputs/ControlledInputText";
import ControlledInputSelect from "../../components/inputs/ControlledInputSelect";
import { CustomErrorBox } from "../../components/inputs/StyledComponents";
import { SpinnerButton } from "../../components/SpinnerButton";

import { useTranslation } from "react-i18next";
import { useFeedError, useFeedSuccess } from "../../utils/feedHooks";
import { useForm, useWatch } from "react-hook-form";
import { SetProbabilityConfigFormValues } from "./types";
import {
  PresetCount,
  PRESET_DEFAULT,
  ProbabilityConfigOptionName,
  ProbabilityElementName,
  setProbabilityConfigInitialValues,
  VirtualGoodType,
  ProbabilityConfigGroupNameArray,
  ProbabilityConfigGroupName,
} from "./helpers";
import { DropDownDataItem } from "../../components/inputs/consts";
import {
  useCreateUpdateProbabilityConfigMutation,
  useGetProbabilityConfigMutation,
  useGetProbabilityDefaultPresetsQuery,
  useGetProbabilityPresetsQuery,
} from "../../slices/probabilityConfig/api";
import {
  CreateUpdateProbabilityConfigBody,
  ProbabilityItemType,
} from "../../slices/probabilityConfig/types";
import { QueryListName } from "../../slices/lists/types";
import { useGetVirtualGoodCharacterPetFieldsQuery } from "../../slices/virtualGoodCharacterPet/api";

const SetProbabilityConfigToGood = () => {
  const { t } = useTranslation();
  const feedError = useFeedError();
  const feedSuccess = useFeedSuccess();
  const [hasErrors, setHasErrors] = useState(false);
  const [mainElement, setMainElement] = useState("");
  const isFormDisabled = true;
  const [isPresetDisabled, setIsPresetDisabled] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [shortCodeList, setShortCodeList] = useState<DropDownDataItem[]>([]);

  const {
    isLoading: isLoadingvirtualGoodCharacterPetFields,
    data: virtualGoodCharacterPetFields,
    error: errorvirtualGoodCharacterPetFields,
    // refetch,
  } = useGetVirtualGoodCharacterPetFieldsQuery(
    QueryListName.virtualGoodCharacterPetFields
  );
  console.log("err1", errorvirtualGoodCharacterPetFields);

  console.log("err1", errorvirtualGoodCharacterPetFields);

  const { data: probabilityDefaultPresets } =
    useGetProbabilityDefaultPresetsQuery();

  const {
    isLoading: isLoadingProbabilityPresets,
    isFetching: isFetchingProbabilityPresets,
    data: probabilityPresets,
    refetch: refetchProbabilityPresets,
  } = useGetProbabilityPresetsQuery();

  const groupTypesList = ProbabilityConfigGroupNameArray.map((el) => ({
    value: el,
    label: el,
  }));

  const presetList = useMemo(() => {
    const data = probabilityPresets?.map((item) => ({
      value: item.name,
      label: item.name,
    }));

    data?.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (b.value > a.value) {
        return 1;
      }
      return 0;
    });

    return [
      {
        value: PRESET_DEFAULT,
        label: PRESET_DEFAULT,
      },
      ...(data || []),
    ];
  }, [probabilityPresets]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
    setValue,
  } = useForm<SetProbabilityConfigFormValues>({
    defaultValues: setProbabilityConfigInitialValues,
  });

  const [shortCode, preset, name, groupType] = useWatch({
    control,
    name: ["shortCode", "preset", "name", "groupType"],
  });

  const currentRarity = useMemo(() => {
    if (
      (virtualGoodCharacterPetFields &&
        groupType === ProbabilityConfigGroupName.items) ||
      groupType === ProbabilityConfigGroupName.food
    ) {
      const value = virtualGoodCharacterPetFields[groupType]?.find(
        (item) => item.shortCode === shortCode
      );

      return value?.propertySet?.rarity.rarity;
    }
    return null;
  }, [shortCode, virtualGoodCharacterPetFields, groupType]);

  const [getProbabilityConfig, { isLoading: isLoadingProbabilityConfig }] =
    useGetProbabilityConfigMutation();

  const getProbabilityConfigAction = useCallback(
    (shortCode: string) => {
      getProbabilityConfig({
        shortCode,
        groupType,
      })
        .unwrap()
        .then((msgData) => {
          const presetIdMsg = msgData.presetId;
          if (presetIdMsg) {
            const presetCur = probabilityPresets.find(
              (el) => el._id == presetIdMsg
            );
            setValue("preset", presetCur.name);
          } else setValue("preset", null);
          const data = msgData.result;
          const probabilityElementNames = Object.keys(ProbabilityElementName);
          if (data) {
            for (const elementName of probabilityElementNames) {
              for (let i = 1; i <= 6; i += 1) {
                setValue(
                  `${elementName}_${i}` as keyof SetProbabilityConfigFormValues,
                  data[`${elementName}_${i}`]
                );
              }
            }
          }
          const virtualGoodItem = virtualGoodCharacterPetFields[groupType].find(
            (el) => el.shortCode === shortCode
          );

          const mainElement = virtualGoodItem.tags.find((el) =>
            probabilityElementNames.some((pr) => pr === el)
          );
          setMainElement(mainElement);
        });
    },
    [
      getProbabilityConfig,
      setValue,
      groupType,
      virtualGoodCharacterPetFields,
      probabilityPresets,
    ]
  );

  useEffect(() => {
    if (shortCode) {
      getProbabilityConfigAction(shortCode);
    }
  }, [getProbabilityConfigAction, shortCode]);

  useEffect(() => {
    if (!virtualGoodCharacterPetFields) return;
    if (groupType) {
      switch (groupType) {
        case "items":
        case "food": {
          const data = virtualGoodCharacterPetFields[groupType]?.reduce(
            (result, item) =>
              item.type === VirtualGoodType.VGOOD
                ? [
                    ...result,
                    {
                      value: item.shortCode,
                      label: item.shortCode,
                    },
                  ]
                : result,

            []
          );

          data?.sort((a, b) => {
            if (a.value < b.value) {
              return -1;
            }
            if (b.value > a.value) {
              return 1;
            }
            return 0;
          });
          virtualGoodCharacterPetFields[groupType];
          setShortCodeList(data);
          break;
        }
        case "character":
        case "pets":
          setShortCodeList(
            virtualGoodCharacterPetFields[groupType].reduce((result, item) => {
              return [
                ...result,
                {
                  value: item,
                  label: item,
                },
              ];
            }, [])
          );
          break;
        default:
          break;
      }
    }
    setValue("preset", null);
  }, [virtualGoodCharacterPetFields, groupType]);

  useEffect(() => {
    if (shortCode !== "" && preset !== "" && shortCode && preset) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [preset, shortCode, groupType]);

  useEffect(() => {
    if (shortCode?.length > 0 && isPresetDisabled) {
      setIsPresetDisabled(false);
    }

    if (shortCode?.length < 1 && !isPresetDisabled) {
      setIsPresetDisabled(true);
    }
  }, [isPresetDisabled, shortCode?.length]);

  const [
    createUpdateProbabilityConfig,
    { isLoading: isLoadingCreateUpdateProbabilityConfig },
  ] = useCreateUpdateProbabilityConfigMutation();

  const createUpdateProbabilityConfigAction = useCallback(
    async (data: SetProbabilityConfigFormValues) => {
      try {
        const items: ProbabilityItemType = {};
        for (const probabilityConfigOptionName in ProbabilityConfigOptionName) {
          items[probabilityConfigOptionName] =
            data[probabilityConfigOptionName];
        }
        const obj: CreateUpdateProbabilityConfigBody = {
          shortCode: data.shortCode,
          groupType,
          items,
          presetId: probabilityPresets.find((el) => el.name == preset)?._id,
        };
        await createUpdateProbabilityConfig(obj).unwrap();
        feedSuccess(t("setProbabilityConfig.successMsgAfterSubmit"));
      } catch (error) {
        feedError(t("setProbabilityConfig.errorMsgAfterSubmit"));
      }
    },
    [
      groupType,
      createUpdateProbabilityConfig,
      feedError,
      feedSuccess,
      t,
      preset,
      probabilityPresets,
    ]
  );

  const onSubmitError = useCallback((errors) => {
    setHasErrors(true);
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setMainElement("");
    }
  }, [isSubmitSuccessful, reset, setValue]);

  const handleSetPreset = useCallback(() => {
    const probabilityElementNames = Object.keys(ProbabilityElementName);
    switch (preset) {
      case "default": {
        const virtualGoodItem = virtualGoodCharacterPetFields[groupType]?.find(
          (el) => el.shortCode === shortCode
        );

        const probabilityDefaultPresetItem = probabilityDefaultPresets.find(
          (el) =>
            el.element == mainElement &&
            el.rarity === virtualGoodItem.propertySet.rarity.rarity
        );

        for (const elementName of probabilityElementNames) {
          if (elementName === mainElement) {
            for (let i = 1; i <= 6; i += 1) {
              setValue(
                `${elementName}_${i}` as keyof SetProbabilityConfigFormValues,
                probabilityDefaultPresetItem[`level${i}Priority`]
              );
            }
          } else {
            for (let i = 1; i <= 6; i += 1) {
              setValue(
                `${elementName}_${i}` as keyof SetProbabilityConfigFormValues,
                probabilityDefaultPresetItem.defaultPriority
              );
            }
          }
        }
        break;
      }
      default: {
        const probabilityPresetItem = probabilityPresets.find(
          (el) => el.name == preset
        );
        for (const elementName of probabilityElementNames) {
          for (let i = 1; i <= 6; i += 1) {
            setValue(
              `${elementName}_${i}` as keyof SetProbabilityConfigFormValues,
              probabilityPresetItem.items[`${elementName}_${i}`]
            );
          }
        }
        setValue("name", preset);
        break;
      }
    }
  }, [
    preset,
    probabilityDefaultPresets,
    groupType,
    shortCode,
    virtualGoodCharacterPetFields,
    mainElement,
    setValue,
    probabilityPresets,
  ]);
  useEffect(() => {
    if (preset) {
      handleSetPreset();
    }
  }, [handleSetPreset, preset]);

  return (
    <Box sx={{ p: 2, bgcolor: "white" }}>
      <form
        onSubmit={handleSubmit(
          createUpdateProbabilityConfigAction,
          onSubmitError
        )}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <ControlledInputSelect
            control={control}
            title={t("setProbabilityConfig.selectGroupType")}
            fieldName="groupType"
            dropDownData={groupTypesList}
            isLoadingDropdownData={false}
            isRequired
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <ControlledInputSelect
            control={control}
            title={t("setProbabilityConfig.selectVirtualGood")}
            fieldName="shortCode"
            dropDownData={shortCodeList}
            isLoadingDropdownData={isLoadingvirtualGoodCharacterPetFields}
            isRequired
          />
          {currentRarity && (
            <Title isDisabled>
              {t("setProbabilityConfig.rarity")}: {currentRarity}
            </Title>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <ControlledInputSelect
            control={control}
            title={t("setProbabilityConfig.selectPreset")}
            fieldName="preset"
            dropDownData={presetList}
            isLoadingDropdownData={
              isLoadingProbabilityPresets ||
              isFetchingProbabilityPresets ||
              isLoadingProbabilityConfig
            }
            isDisabled={isPresetDisabled}
          />
          {/* <SpinnerButton
            type="button"
            onClick={handleSetPreset}
            buttonName={t("buttonNames.set")}
            loading={false}
            disabled={isPresetDisabled}
          /> */}
        </Stack>

        <Stack direction="row" ml={2} my={4} spacing={7}>
          <Stack>
            <Title isDisabled={isFormDisabled}>
              {`${t("setProbabilityConfig.airTitle")}${
                mainElement === "air" ? t("setProbabilityConfig.main") : ""
              }`}
            </Title>
            {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
              <ControlledInputText
                key={numb}
                control={control}
                title={numb}
                fieldName={`air_${numb}`}
                titleWidth={20}
                inputWidth={130}
                isInputDisabled={isFormDisabled}
                isNumeric
              />
            ))}
          </Stack>
          <Stack>
            <Title isDisabled={isFormDisabled}>
              {`${t("setProbabilityConfig.forestTitle")}${
                mainElement === "forest" ? t("setProbabilityConfig.main") : ""
              }`}
            </Title>
            {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
              <ControlledInputText
                key={numb}
                control={control}
                title={numb}
                fieldName={`forest_${numb}`}
                titleWidth={20}
                inputWidth={130}
                isInputDisabled={isFormDisabled}
                isNumeric
              />
            ))}
          </Stack>
          <Stack>
            <Title isDisabled={isFormDisabled}>
              {`${t("setProbabilityConfig.groundTitle")}${
                mainElement === "ground" ? t("setProbabilityConfig.main") : ""
              }`}
            </Title>
            {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
              <ControlledInputText
                key={numb}
                control={control}
                title={numb}
                fieldName={`ground_${numb}`}
                titleWidth={20}
                inputWidth={130}
                isInputDisabled={isFormDisabled}
                isNumeric
              />
            ))}
          </Stack>
          <Stack>
            <Title isDisabled={isFormDisabled}>
              {`${t("setProbabilityConfig.waterTitle")}${
                mainElement === "water" ? t("setProbabilityConfig.main") : ""
              }`}
            </Title>
            {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
              <ControlledInputText
                key={numb}
                control={control}
                title={numb}
                fieldName={`water_${numb}`}
                titleWidth={20}
                inputWidth={130}
                isInputDisabled={isFormDisabled}
                isNumeric
              />
            ))}
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2} sx={{ my: 4 }}>
          <SpinnerButton
            variant="contained"
            buttonName={t("setProbabilityConfig.saveProbabilityConfig")}
            loading={isLoadingCreateUpdateProbabilityConfig}
            disabled={isSaveDisabled}
          />
          {hasErrors && (
            <CustomErrorBox>
              {t("errors.hasErrorsOnSubmitMessage")}
            </CustomErrorBox>
          )}
        </Stack>
      </form>
    </Box>
  );
};

export default memo(SetProbabilityConfigToGood);

const Title = ({ children, isDisabled }) => (
  <Typography
    variant="h6"
    sx={{
      ml: 2,
      color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "initial",
    }}
  >
    {children}
  </Typography>
);
