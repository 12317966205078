import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import { ROUTE_PATHS } from "../../consts";

export const virtualGoodCharacterPetApi = createApi({
  reducerPath: "virtualGoodCharacterPetApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: ["virtualGoodCharacterPet"],
  endpoints: (builder) => ({
    getVirtualGoodCharacterPetFields: builder.query<any, any>({
      query: (body) => {
        return {
          url: `${ROUTE_PATHS.LISTS}`,
          method: "POST",
          body: {
            listName: body,
          },
        };
      },
    }),
  }),
});

export const { useGetVirtualGoodCharacterPetFieldsQuery } =
  virtualGoodCharacterPetApi;
