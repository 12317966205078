import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import {
  CreateUpdateProbabilityConfigBody,
  GetProbabilityConfigBody,
  ProbabilityDefaultPreset,
  ProbabilityItemResponseType,
  ProbabilityPreset,
} from "./types";

import {
  COLLECTION_NAMES,
  CREATE_ITEM_TYPE,
  DB_NAMES,
  ROUTE_PATHS,
} from "../../consts";

const { GET, CREATE_UPDATE, PROBABILITY_CONFIG, SEARCH, ALL, CREATE } =
  ROUTE_PATHS;

export const probabilityConfigApi = createApi({
  reducerPath: "probabilityConfigApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: ["ProbabilityConfig"],
  endpoints: (builder) => ({
    getProbabilityPresets: builder.query<ProbabilityPreset[], void>({
      query: () => {
        return {
          url: `${SEARCH}${ALL}`,
          method: "POST",
          body: {
            dbName: DB_NAMES.META,
            collectionName: COLLECTION_NAMES.PROBABILITY_PRESET,
            search: null,
          },
        };
      },
    }),

    getProbabilityDefaultPresets: builder.query<
      ProbabilityDefaultPreset[],
      void
    >({
      query: () => {
        return {
          url: `${SEARCH}${ALL}`,
          method: "POST",
          body: {
            dbName: DB_NAMES.META,
            collectionName: COLLECTION_NAMES.PROBABILITY_DEFAULT_CONFIG,
            search: null,
          },
        };
      },
    }),

    getProbabilityConfig: builder.mutation<
      ProbabilityItemResponseType,
      GetProbabilityConfigBody
    >({
      query: (body) => {
        return { url: `${PROBABILITY_CONFIG}${GET}`, method: "POST", body };
      },
    }),

    createUpdateProbabilityConfig: builder.mutation<
      void,
      CreateUpdateProbabilityConfigBody
    >({
      query: (body) => ({
        url: `${PROBABILITY_CONFIG}${CREATE_UPDATE}`,
        method: "POST",
        body,
      }),
    }),
    createUpdateProbabilityPreset: builder.mutation<void, ProbabilityPreset>({
      query: (body) => ({
        url: `${SEARCH}${CREATE}`,
        method: "POST",
        body: {
          data: { ...body, __type: CREATE_ITEM_TYPE.probabilityConfigPreset },
          dbName: DB_NAMES.META,
          collectionName: COLLECTION_NAMES.PROBABILITY_PRESET,
        },
      }),
    }),
  }),
});

export const {
  useGetProbabilityPresetsQuery,
  useGetProbabilityDefaultPresetsQuery,
  useGetProbabilityConfigMutation,
  useCreateUpdateProbabilityConfigMutation,
  useCreateUpdateProbabilityPresetMutation,
} = probabilityConfigApi;
