import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@mui/system";
import { Stack, Typography, Tabs, Tab } from "@mui/material";
import ControlledInputText from "../../components/inputs/ControlledInputText";
import ControlledInputSelect from "../../components/inputs/ControlledInputSelect";
import { SpinnerButton } from "../../components/SpinnerButton";

import { useTranslation } from "react-i18next";
import { useFeedError, useFeedSuccess } from "../../utils/feedHooks";
import { useForm, useWatch } from "react-hook-form";
import { useGetVirtualGoodsQuery } from "../../slices/virtualGoods/api";
import { ManagePresetsFormValues } from "./types";
import {
  PresetCount,
  PRESET_DEFAULT,
  ProbabilityConfigOptionName,
  ProbabilityElementName,
  managePresetsInitialValues,
  VirtualGoodType,
} from "./helpers";
import { DropDownDataItem } from "../../components/inputs/consts";
import {
  useCreateUpdateProbabilityPresetMutation,
  useGetProbabilityDefaultPresetsQuery,
  useGetProbabilityPresetsQuery,
} from "../../slices/probabilityConfig/api";
import { ProbabilityItemType } from "../../slices/probabilityConfig/types";
import { QueryListName } from "../../slices/lists/types";

const ManagePresets = () => {
  const { t } = useTranslation();
  const feedError = useFeedError();
  const feedSuccess = useFeedSuccess();
  const [hasErrors, setHasErrors] = useState(false);
  const [mainElement, setMainElement] = useState("");
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const {
    isLoading: isLoadingVirtualGoods,
    data: virtualGoods,
    // error,
    // refetch,
  } = useGetVirtualGoodsQuery(QueryListName.virtualGoodBaseFields);

  const { data: probabilityDefaultPresets } =
    useGetProbabilityDefaultPresetsQuery();

  const {
    isLoading: isLoadingProbabilityPresets,
    isFetching: isFetchingProbabilityPresets,
    data: probabilityPresets,
    refetch: refetchProbabilityPresets,
  } = useGetProbabilityPresetsQuery();

  const shortCodeList = (): DropDownDataItem[] => {
    const data = virtualGoods?.reduce(
      (result, item) =>
        item.type === VirtualGoodType.VGOOD
          ? [
              ...result,
              {
                value: item.shortCode,
                label: item.shortCode,
              },
            ]
          : result,

      []
    );

    data?.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (b.value > a.value) {
        return 1;
      }
      return 0;
    });

    return data || [];
  };

  const presetList = useMemo(() => {
    const data = probabilityPresets?.map((item) => ({
      value: item.name,
      label: item.name,
    }));

    data?.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (b.value > a.value) {
        return 1;
      }
      return 0;
    });

    return [
      {
        value: PRESET_DEFAULT,
        label: PRESET_DEFAULT,
      },
      ...(data || []),
    ];
  }, [probabilityPresets]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
    setValue,
  } = useForm<ManagePresetsFormValues>({
    defaultValues: managePresetsInitialValues,
  });

  const [shortCode, preset, name] = useWatch({
    control,
    name: ["shortCode", "preset", "name"],
  });

  const currentRarity = useMemo(() => {
    const value = virtualGoods?.find((item) => item.shortCode === shortCode);

    return value?.propertySet?.rarity.rarity;
  }, [shortCode, virtualGoods]);

  const [
    createUpdateProbabilityPreset,
    { isLoading: isLoadingCreateUpdateProbabilityPreset },
  ] = useCreateUpdateProbabilityPresetMutation();

  const createUpdateProbabilityConfigPreset = useCallback(async () => {
    try {
      if (name && name !== "" && name.length >= 3 && name !== "default") {
        const items: ProbabilityItemType = {};
        for (const probabilityConfigOptionName in ProbabilityConfigOptionName) {
          items[probabilityConfigOptionName] =
            control._formValues[probabilityConfigOptionName];
        }
        await createUpdateProbabilityPreset({ name, items }).unwrap();

        feedSuccess(
          t(
            "setProbabilityConfig.successMsgAfterCreateUpdateProbabilityConfigPresetSubmit"
          )
        );
        refetchProbabilityPresets();
        setValue("preset", name);
      } else {
        feedError(
          t(
            "setProbabilityConfig.errorMsgAfterCreateUpdateProbabilityConfigPresetValidation"
          )
        );
      }
    } catch (error) {
      feedError(
        t(
          "setProbabilityConfig.errorMsgAfterCreateUpdateProbabilityConfigPresetSubmit"
        )
      );
    }
  }, [
    control._formValues,
    createUpdateProbabilityPreset,
    feedError,
    feedSuccess,
    name,
    refetchProbabilityPresets,
    t,
  ]);

  const onSubmitError = useCallback((errors) => {
    setHasErrors(true);
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setIsFormDisabled(true);
      setMainElement("");
    }
  }, [isSubmitSuccessful, reset, setValue]);

  const handleSetPreset = useCallback(() => {
    const probabilityElementNames = Object.keys(ProbabilityElementName);
    switch (preset) {
      case "default": {
        const virtualGoodItem = virtualGoods?.find(
          (el) => el.shortCode === shortCode
        );

        const probabilityDefaultPresetItem = probabilityDefaultPresets.find(
          (el) =>
            el.element == mainElement &&
            el.rarity === virtualGoodItem.propertySet.rarity.rarity
        );

        for (const elementName of probabilityElementNames) {
          if (elementName === mainElement) {
            for (let i = 1; i <= 6; i += 1) {
              setValue(
                `${elementName}_${i}` as keyof ManagePresetsFormValues,
                probabilityDefaultPresetItem[`level${i}Priority`]
              );
            }
          } else {
            for (let i = 1; i <= 6; i += 1) {
              setValue(
                `${elementName}_${i}` as keyof ManagePresetsFormValues,
                probabilityDefaultPresetItem.defaultPriority
              );
            }
          }
        }
        break;
      }
      default: {
        const probabilityPresetItem = probabilityPresets.find(
          (el) => el.name == preset
        );
        if (probabilityPresetItem) {
          for (const elementName of probabilityElementNames) {
            for (let i = 1; i <= 6; i += 1) {
              setValue(
                `${elementName}_${i}` as keyof ManagePresetsFormValues,
                probabilityPresetItem.items[`${elementName}_${i}`]
              );
            }
          }
          setValue("name", preset);
        }
        break;
      }
    }
    setIsFormDisabled(false);
  }, [
    preset,
    probabilityDefaultPresets,
    shortCode,
    virtualGoods,
    mainElement,
    setValue,
    probabilityPresets,
  ]);

  useEffect(() => {
    if (preset) {
      handleSetPreset();
    }
  }, [handleSetPreset, preset]);
  preset;
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ p: 2, bgcolor: "white" }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <ControlledInputSelect
          control={control}
          title={t("setProbabilityConfig.selectPreset")}
          fieldName="preset"
          dropDownData={presetList}
          isLoadingDropdownData={
            isLoadingProbabilityPresets || isFetchingProbabilityPresets
          }
          isDisabled={false}
        />
      </Stack>

      <Stack direction="row" ml={2} my={4} spacing={7}>
        <Stack>
          <Title isDisabled={isFormDisabled}>
            {`${t("setProbabilityConfig.airTitle")}${
              mainElement === "air" ? t("setProbabilityConfig.main") : ""
            }`}
          </Title>
          {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
            <ControlledInputText
              key={numb}
              control={control}
              title={numb}
              fieldName={`air_${numb}`}
              titleWidth={20}
              inputWidth={130}
              isInputDisabled={isFormDisabled}
              isNumeric
            />
          ))}
        </Stack>
        <Stack>
          <Title isDisabled={isFormDisabled}>
            {`${t("setProbabilityConfig.forestTitle")}${
              mainElement === "forest" ? t("setProbabilityConfig.main") : ""
            }`}
          </Title>
          {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
            <ControlledInputText
              key={numb}
              control={control}
              title={numb}
              fieldName={`forest_${numb}`}
              titleWidth={20}
              inputWidth={130}
              isInputDisabled={isFormDisabled}
              isNumeric
            />
          ))}
        </Stack>
        <Stack>
          <Title isDisabled={isFormDisabled}>
            {`${t("setProbabilityConfig.groundTitle")}${
              mainElement === "ground" ? t("setProbabilityConfig.main") : ""
            }`}
          </Title>
          {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
            <ControlledInputText
              key={numb}
              control={control}
              title={numb}
              fieldName={`ground_${numb}`}
              titleWidth={20}
              inputWidth={130}
              isInputDisabled={isFormDisabled}
              isNumeric
            />
          ))}
        </Stack>
        <Stack>
          <Title isDisabled={isFormDisabled}>
            {`${t("setProbabilityConfig.waterTitle")}${
              mainElement === "water" ? t("setProbabilityConfig.main") : ""
            }`}
          </Title>
          {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
            <ControlledInputText
              key={numb}
              control={control}
              title={numb}
              fieldName={`water_${numb}`}
              titleWidth={20}
              inputWidth={130}
              isInputDisabled={isFormDisabled}
              isNumeric
            />
          ))}
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        <ControlledInputText
          control={control}
          title={t("setProbabilityConfig.configName")}
          fieldName="name"
          inputWidth={300}
          isInputDisabled={isFormDisabled}
        />
        <SpinnerButton
          type="button"
          onClick={createUpdateProbabilityConfigPreset}
          buttonName={t("buttonNames.save")}
          loading={isLoadingCreateUpdateProbabilityPreset}
          disabled={isFormDisabled}
        />
      </Stack>
    </Box>
  );
};

export default memo(ManagePresets);

const Title = ({ children, isDisabled }) => (
  <Typography
    variant="h6"
    sx={{
      ml: 2,
      color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "initial",
    }}
  >
    {children}
  </Typography>
);
