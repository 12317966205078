import React, { memo, useState } from "react";

import { Box } from "@mui/system";
import { Tabs, Tab } from "@mui/material";
import SetProbabilityConfigToGood from "./SetProbabilityConfigToGood";
import ManagePresets from "./ManagePresets";

const SetProbabilityConfigTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ p: 2, bgcolor: "white" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="virtual goods" />
        <Tab label="presets" />
      </Tabs>
      {activeTab === 0 && <SetProbabilityConfigToGood />}
      {activeTab === 1 && <ManagePresets />}
    </Box>
  );
};

export default memo(SetProbabilityConfigTabs);
