import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { reducer as authReducer } from "./slices/auth/slice";
import roleReducer from "./slices/Roles/slice";
import websocketDataReducer from "./slices/Websocket/slice";
import playersDataReducer from "./slices/players/slice";
import questsDataReducer from "./slices/quests/slice";
import {
  TypedUseSelectorHook,
  useDispatch as useDefaultDispatch,
  useSelector as useDefaultSelector,
} from "react-redux";
import { playersApi } from "./slices/players/api";
import { listsApi } from "./slices/lists/api";
import { virtualGoodsApi } from "./slices/virtualGoods/api";
import { questsApi } from "./slices/quests/api";
import { websocketApi } from "./slices/Websocket/api";
import { groupManagementApi } from "./slices/groupManagement/api";
import { feedbackApi } from "./slices/feedback/api";
import { analyticsApi } from "./slices/analytics/api";
import { probabilityConfigApi } from "./slices/probabilityConfig/api";
import { distributionSystemApi } from "./slices/distributionSystem/api";
import { storeContentApi } from "./slices/storeContent/api";
import { virtualGoodCharacterPetApi } from "./slices/virtualGoodCharacterPet/api";

const reducer = combineReducers({
  roles: roleReducer,
  auth: authReducer,
  websocketData: websocketDataReducer,
  playersData: playersDataReducer,
  questsData: questsDataReducer,
  [playersApi.reducerPath]: playersApi.reducer,
  [listsApi.reducerPath]: listsApi.reducer,
  [virtualGoodsApi.reducerPath]: virtualGoodsApi.reducer,
  [virtualGoodCharacterPetApi.reducerPath]: virtualGoodCharacterPetApi.reducer,
  [questsApi.reducerPath]: questsApi.reducer,
  [groupManagementApi.reducerPath]: groupManagementApi.reducer,
  [websocketApi.reducerPath]: websocketApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
  [analyticsApi.reducerPath]: analyticsApi.reducer,
  [probabilityConfigApi.reducerPath]: probabilityConfigApi.reducer,
  [distributionSystemApi.reducerPath]: distributionSystemApi.reducer,
  [storeContentApi.reducerPath]: storeContentApi.reducer,
});

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["auth.authUser"],
        ignoredActionPaths: ["payload.authUser"],
        ignoredActions: [
          "feedbackApi/executeQuery/fulfilled",
          "feedbackApi/executeMutation/fulfilled",
          "websocket/executeMutation/fulfilled",
          "virtualGoodsApi/executeQuery/fulfilled",
          "virtualGoodCharacterPetApi/executeQuery/fulfilled",
          "events/executeQuery/fulfilled",
          "playersApi/executeQuery/fulfilled",
          "lists/executeQuery/fulfilled",
          "questsApi/executeQuery/fulfilled",
        ],
      },
    }).concat(
      playersApi.middleware,
      listsApi.middleware,
      virtualGoodsApi.middleware,
      virtualGoodCharacterPetApi.middleware,
      questsApi.middleware,
      websocketApi.middleware,
      groupManagementApi.middleware,
      feedbackApi.middleware,
      analyticsApi.middleware,
      probabilityConfigApi.middleware,
      distributionSystemApi.middleware
    ),
});

export type State = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
export type ThunkApi = {
  dispatch: Dispatch;
  state: State;
};

export const useDispatch = () => useDefaultDispatch<Dispatch>();
export const useSelector: TypedUseSelectorHook<State> = useDefaultSelector;
