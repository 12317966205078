import React, { useCallback, useState } from "react";
import { Box, Container, Grid, Stack, Tab, Tabs } from "@mui/material";

import { Helmet } from "react-helmet";
import { WideDrawer } from "../../components/WideDrawerForm/WideDrawer";
import { Edit } from "../../components/Edit/Edit";

import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { useTitle } from "../../utils/useTitle";
import {
  useEditVirtualGoodMutation,
  useGetVirtualGoodsQuery,
} from "../../slices/virtualGoods/api";
import { COLLECTION_NAMES, DB_NAMES, ROUTE_PATHS } from "../../consts";
import SetProbabilityConfig from "./SetProbabilityConfigTabs";
import SetProbabilityConfigToGood from "./SetProbabilityConfigToGood";
import ManagePresets from "./ManagePresets";

const { V_GOODS, ADD, SET } = ROUTE_PATHS;
const collectionName = COLLECTION_NAMES.VIRTUAL_GOODS;
const dbName = DB_NAMES.META;

const VirtualGoods = () => {
  const { t } = useTranslation();
  const title = useTitle(t("virtualGoods.title"));
  const editVirtualGoodsTitle = useTitle(t("virtualGoods.editVirtualGoods"));

  const { id, detailsDrawerOpen, closeDrawer, addDrawerOpen, setDrawerOpen } =
    useTableUrlTools(V_GOODS);
  const handleCloseDrawer = useCallback(
    () => closeDrawer(V_GOODS),
    [closeDrawer]
  );

  const getQueryData = useGetVirtualGoodsQuery;

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <Stack direction="row" spacing={2} mb={2} ml={2} mt={2}>
          <Box sx={{ p: 2, bgcolor: "white" }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label={t("setProbabilityConfig.mainTitle")} />
              <Tab label={t("setProbabilityConfig.presetsTitle")} />
            </Tabs>
            {activeTab === 0 && <SetProbabilityConfigToGood />}
            {activeTab === 1 && <ManagePresets />}
          </Box>
        </Stack>
      </Container>
      <WideDrawer
        open={detailsDrawerOpen || addDrawerOpen || setDrawerOpen}
        close={handleCloseDrawer}
      >
        {detailsDrawerOpen && (
          <Box m={2.5}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Edit
                  id={id}
                  collectionName={collectionName}
                  dbName={dbName}
                  title={editVirtualGoodsTitle}
                  getQueryData={getQueryData}
                  getEditItemTools={useEditVirtualGoodMutation}
                  errorsDescriptionPath="virtualGoods.errors.edit"
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {setDrawerOpen && <SetProbabilityConfig />}
      </WideDrawer>
    </>
  );
};

export default VirtualGoods;
